<template>
  <common-page-table
    :titleTable="$t('Коды бюджетные')"
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :prefixEdit="`/contract/${$route.params.ContractId}/ContractCodeBudget`"
    :prefixShow="`/contract/${$route.params.ContractId}/ContractCodeBudget`"
    :linkCreate="linkCreate"
    :keyTable="'codeId'"
    :alternativeConverter="convertData"
    :isDefaultConverter="false"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
    :hasReadOnlyInTable="hasReadOnlyInTable"


    isDopLinkPluse
    :customDopLink="customDopLink"
    pluslinktitle="Создать значение или измениить"
    :wAction="240"
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS , FIELD_LANG_CODE} from "../../../../constant";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";

export default {
  name: "TablePageBudgetСodeContract",

  components: { CommonPageTable },
  props: {
    isClientCode: { type: Boolean, default: false },
    linkCreate: { type: String, default: `/code/create` },
    customDelete: { type: Boolean, default: false },
    hasReadOnlyInTable: { type: Boolean, default: true }, 
    apiMethodDeleteIn: { type: String }, 
    deleteParamsIn: { type: Function }
  },
  data() {
    return {
      fieldLang: FIELD_LANG_CODE,
      apiName: API_METHODS.GET_CONTRACT_CODE,
    };
  },
  computed: {
    paramsGetData() {
      return {
            AgencyId: 0,
            ContractId: this.$route.params.ContractId,
            CodeType: 'Budget'  
      };
    },
    nameRoleEntity() {
      return NAME_OBJECT.clientCodesBudget;
    },
    apiDeleteName(){
      if(!this.customDelete){
        return  API_METHODS.COMPANY_UNCONNECT_CODE
      } else{
        return this.apiMethodDeleteIn
      }
    }
  },
  methods: {
     customDopLink(row) {
        return `/contract/${row.contractId}/ContractCodeBudget/${row.codeId}/ContractCodeBudgetValue/update`
      },
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow, FIELD_LANG_CODE);
      return res;
    },
    deleteParams(id) {
       if (!this.customDelete) { 
          return{   
            CompanyId: this.$route.params.CompanyId,
            CodeId: id,
          }
       }  else {
         return this.deleteParamsIn(id)
       } 
      return {}
    }
  }
};
</script>
