<template>
  <common-page-table
    :titleTable="$t('Персоны')"
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :hasLang="false"
    :prefixEdit="`/contract/${$route.params.ContractId}/person`"
    :prefixShow="`/contract/${$route.params.ContractId}/person`"
    :link-create="`/contract/${$route.params.ContractId}/person/create`"
    :keyTable="'personId'"
    :alternativeConverter="convertData"
    :isDefaultConverter="false"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"

    :force-is-edit="false"
    :force-delete="false"
  />
</template>
<script>
import { NAME_OBJECT, LIST_EQUIVALENT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS, FIELD_LANG_PERSON } from "../../../../constant";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";
import formatData from "../../../../helpers/converters/formatData";
export default {
  name: "TablePagePerson",
  components: { CommonPageTable },
  data() {
    return {
      fieldLang: "FIELD_LANG_PERSON",
      apiName: API_METHODS.GET_ALL_PERSON,
      apiDeleteName: API_METHODS.DELETE_PERSON
    };
  },
  computed: {
    paramsGetData() {
      return { ContractId: this.$route.params.ContractId };
    },
    nameRoleEntity() {
      return NAME_OBJECT.clientPerson;
    }
  },
  methods: {
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow, FIELD_LANG_PERSON);

      res = res.map(person => {
        return { ...person, dateBirth: formatData(person.dateBirth) };
      });
      return res;
    },
    deleteParams(id) {
      return {
        ContractId: this.$route.params.ContractId,
        PersonId: id
      };
    }
  }
};
</script>
