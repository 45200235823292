<template>
  <common-page-edit 
    :title="title"
    :nameRoleEntity="nameRoleEntity"
    :apiNameGet="apiNameGet"
    :apiNameCreate="apiNameCreate"
    :apiNameUpdate="apiNameUpdate"
    routeNameShow="ContractShow"
    routeNameCreate="CreateContract"
    :keyRoute="'ContractId'"
    :backUrl="pathBack"
    @set-loadData="setLoadData"
    :convertLoadData="convertLoadData"
    :paramsSave="paramsSave"
    :isDefaultConverter="false"

    :forceNoShowEdit="true"
  > 
   <template name="block2">
      <contract-data :loadDataIn="loadData" v-if="loadData" :activeShowMenu="true"></contract-data> 
  </template>
  </common-page-edit>
</template>
<script>

import { API_METHODS , FIELD_LANG_PERSON } from "../../../../constant";
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageEdit from '../../commonElement/CommonPageEdit.vue';
import convertFieldInfoListToScheme from "../../../../helpers/converters/convetFieldInfoListToScheme";
import ContractData from "./ContractData.vue"
export default {
  name: "EditPageContract",
  components: {
    CommonPageEdit,
    ContractData
  },
  data() {
    return {
      wait: false,
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,

      routeNameShow: "ContractShow",
      routeNameCreate: "CreateContract",

      pathBack: `/contract`,
     
      apiNameGet: API_METHODS.GET_CONTRACT,
      apiNameCreate:API_METHODS.CREATE_CONTRACT,
      apiNameUpdate:API_METHODS.UPDATE_CONTRACT,
      loadData: null
    };
  },
  computed: {
    nameEnity(){
      return this.$route.params.contracts
    },
    title() {
      let text1 =   this.$t("Просмотр договора");
      let text2 =  this.$t("Создание договора");
      let text3 = this.$t("Редактирование договора");

      let findShow = this.$route.path.indexOf('show')
      if(findShow !=-1) {
        return text1
      }
      let findCreate = this.$route.path.indexOf('create')
      if(findCreate !=-1) {
        return text2
      }
      let findEdit = this.$route.path.indexOf('edit')
      if(findEdit !=-1) {
        return text3
      }
      return text1
    },
   nameRoleEntity(){
         return NAME_OBJECT.attrEntity
   },
  },
  methods:{
    convertLoadData(res){
          let loadData = res.object;
          let fieldInfoList = res.fieldInfoList;
          let scheme = {configForm: []}  
          scheme.configForm = convertFieldInfoListToScheme({
              fieldInfoList, hasLang:false
          });
        
          return {
            loadData, 
            fieldInfoList,
            scheme
          } 
    },
    setLoadData(loadData){
      this.loadData = loadData
    },
   
    paramsSave() {
      return {
          id: this.$route.params.ContractId
        }
    }
  }
};
</script>
