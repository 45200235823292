<template>
  <the-table-page :titleTable="$t('Корпоративная политика')"
                  :tableData="tableData"
                  :load="load"
                  fieldId="childId"
                  :updateData="updateData"
                  :fieldInfoList="fieldInfoList"
                  :searchStart="searchStart"
                  :sortData="sortData"
                  :setSort="setSort"
                  :deleteEl="deleteEl"
                  :prefixEdit="`/corpPolicy`"
                  :prefixShow="`/corpPolicy`"
                  :linkCreate="`/contract/${$route.params.ContractId}/addPolicy`"
                  configName = "policyContract"
                  :hasReadOnlyInTable="hasReadOnlyInTable" 
                  :nameTableSaveInCashe="nameTableSaveInCashe"
  ></the-table-page>
</template>
<script>
  import scheme from './scheme.json'
  import TheTablePage from "../../TheTablePage.vue";
  import {POLITY_CONTACT, ENTITY_NAME } from "./store/type";

  import {
    SET_MAIN_PAGINATION,
    SET_MAIN_PAGINATION_PER_PAGE,
    SET_MAIN_PAGINATION_PAGE,
    SET_MAIN_SORT_ENTITY,
    SET_MAIN_SEARCH_ENTITY
  } from "../../../../src/store/type"

  import {READ, DELETE} from '../../../store/type'
  import {mapState} from 'vuex';
  import NotificationSample from 'src/pages/Dashboard/Components/NotificationSample';
  import {DEFAULT_PER_PAGE, TYPE_ERRORS, FIELD_LANG_POLICY, GET_MEMORY_PER_PAGE} from '../../../constant'
  import notify from '../../../helpers/notify/notify'
  import convertLangDataToTable from '../../../helpers/converters/convertLangDataToTable'
  export default {
    props:{
      hasReadOnlyInTable: { type: Boolean, default: true }, 
    },
    components: {
      TheTablePage
    },
    data() {
      return {
        scheme: scheme,
        load: false
      }
    },
    computed: {
      ...mapState({
        fieldInfoList: state => state.corpPolity.fieldInfoList,
        lang: state => state.common.lang,
        sortData: state => state.pagination.sortData,
      }),
      nameTableSaveInCashe(){
        return "corpPolity"+"--"+this.$route.path
      },
      tableData() {
          let langNow = this.$store.state.common.lang
          let dataNow = this.$store.state.corpPolity.data || []
          if (dataNow && dataNow.length > 0) {
            dataNow = convertLangDataToTable(dataNow, langNow, FIELD_LANG_POLICY)
            return dataNow
          }
      }
    },
    watch: {
      lang() {
        this.initData()
      }
    },
    methods: {
      getData() {
        this.load= true
        this.$store.dispatch(POLITY_CONTACT + READ, {
        self:this, 
        ContractId: this.$route.params.ContractId,
        ChildType: 'Policy'
        }).then(res=>{
          this.load= false
        })
        .catch(err=>{
          this.load= false
          notify(this, this.$t('Ошибка получения данных'), TYPE_ERRORS.DANGER)
        })
      },
      updateData() {
        this.getData()
      },
      searchStart(search){
        this.$store.commit(SET_MAIN_SEARCH_ENTITY, search)
        this.getData()
      },
      setSort(val){
        this.$store.commit(SET_MAIN_SORT_ENTITY, val)
        this.getData()
      },
      deleteEl(id){
        this.$store.dispatch(POLITY_CONTACT + DELETE, {
            self:this,
            id:id,
            ContractId: this.$route.params.ContractId,
            ChildType: 'Policy'
        }).then(res=>{
          this.getData()
             if(res.errors && res.errors.length  == 0){notify(this, this.$t("Успешно"),TYPE_ERRORS.SUCCESS); }
        })
      },
      initData() {
        this.$store.commit( ENTITY_NAME + READ ,{ data: [], fieldInfoList: []})
        this.$store.commit( SET_MAIN_SORT_ENTITY,{})
        this.$store.commit( SET_MAIN_SEARCH_ENTITY, '')
        this.$store.commit( SET_MAIN_PAGINATION_PER_PAGE, GET_MEMORY_PER_PAGE())
        this.$store.commit( SET_MAIN_PAGINATION_PAGE, 1)
        let name = this.nameTableSaveInCashe ? this.nameTableSaveInCashe : null;
        this.$store.dispatch("SET_OLD_PAGINATION", name)
        this.getData()
      }
    },
    created() {
      this.initData()
    }
  }
</script>
